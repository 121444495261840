<template>
  <v-layout wrap v-if="content" style="margin-top:20px">
    <v-flex xs6>
    <v-checkbox
      v-model="content.GallerySlider"
      label="slider"
      @change="handleChangeIsModified"
    />
    </v-flex>
    <v-flex xs6>
      <v-text-field
        v-show="content.GallerySlider"
        v-model="content.GalleryMobileHeight"
        append-icon="mdi-search"
        label="Forzar altura (solo móviles)"
        hideDetails
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-card 
      v-for="(item, i) in content.Images"
      :key="i"
      style="margin-right: 25px;"
      elevation="0"
      >
      <v-btn
        color="red"
        dark
        small
        absolute
        top
        right
        fab
        :elevation="5"
        style="color:white;margin-right:-35px;"
        @click="handleClearImage(item)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-img
        :src="item"
        height="120px"
        width="120px" 
        :lazy-src="item"
        aspect-ratio="1.7"
        class="grey lighten-2"
        style="margin-bottom: 20px;border-radius: 10%;border: 0.5px solid #56a09930 !important;"
      />
    </v-card>
    <v-card
      v-if="!limit || !content.Images || content.Images.length < limit"
      style="margin-left: 10px;margin-right: 25px;width:100px"
      elevation="0"
    >
      <select-image
        style="margin-top:0px"
        :image="image"
        :folder="workspaceID"
        :onChange="handleChangeImage"
      />
      <!--select-image
        v-model="imageNew"
        :icon="'mdi-plus'"
        :imageWidth="1280"
        :imageHeight="853"
        :imageOld='imageOld'
        :aspectRatio="4/3"
        :enterpriseID="enterpriseID"
        @input="handleAddImage"
        style="margin-left:0px;margin-top:0px;"
      /-->
    </v-card>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import utils from '@/services/utils'
  import SelectImage from '@/components/selectImage/Index'
  export default {
    name: 'Form7Gallery',
    components: {
      SelectImage,
    },
    props: {
      editedItem: {
        type: Object,
        required: true
      },
      onChangeIsModified: {
        type: Function,
        required: true,
      },
    },
    data: () => ({
      content: null,
      image: null,
      limit: 12,
    }),
    computed: {        
      ...mapState('app', ['locale']),
      workspaceID () {
        return utils.getWorkspace ('dtouch')
      },
    },
    watch: {
      editedItem () {
        this.prepareContent()
      },
    },
    mounted () {
      this.prepareContent()
    },
    methods: {
      prepareContent () {
        const aux = this.editedItem
        aux.Images = typeof aux.Images === 'string' ? aux.Images.split(',') : aux.Images
        this.content = aux
      },
      handleChangeImage (v) {
        api.addItem ('dtouch', 'v1/private/utils/media', v)
          .then(response => {
            this.content.Images.push(response.data.url)
            this.image = null
            this.handleChangeIsModified()
          })
      },
      handleClearImage (url) {
        this.content.Images = this.content.Images.filter(x => x !== url)
      },
      handleChangeIsModified () {
        this.onChangeIsModified(this.content) 
      },
    },
  }
</script>


<template>
  <v-layout wrap v-if="content">
    <v-flex xs12 
      v-if="menus"
      style="padding: 10px"
    >
      <v-checkbox 
        v-model="isAll"
        :label="$t('dtouch.menuExtFood.isAll', locale)"
        @change="handleChange"
      />
    </v-flex>
    <v-flex xs12 
      v-if="!isAll && menus"
      style="padding: 10px"
    >
      <v-autocomplete
        v-model="content.MenuExtFoodExternalID"
        :items="menus"
        item-value="ID"
        item-text="Name"
        dense
        multiple
        filled
        :label="$t('dtouch.menuExtFood.externalID', locale)"
        hide-details
        @change="handleChange"
      />
    </v-flex>
    <v-flex xs12 
      style="padding: 10px"
    >
      <v-autocomplete
        v-model="content.MenuExtFoodDayOfWeek"
        :items="daysOfWeek"
        item-value="id"
        item-text="name"
        dense
        filled
        label="Día de la semana"
        hide-details
        @change="handleChange"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
export default {
  name: 'Form49MenuExtFood',
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    content: null,
    menus: null,
    isAll: true,
  }),
  computed: {        
    ...mapState('app', ['locale', ]),
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
    daysOfWeek () {
      return [
        { id: null, name: 'Todos'},
        { id: 1, name: 'Lunes'},
        { id: 2, name: 'Martes'},
        { id: 3, name: 'Miércoles'},
        { id: 4, name: 'Jueves'},
        { id: 5, name: 'Viernes'},
        { id: 6, name: 'Sábado'},
        { id: 0, name: 'Domingo'},
      ]
    },
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
    locale () {
      this.prepareMenusName()
    },
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    prepareContent () {
      this.handleGetMenus()
      const aux = this.editedItem
      this.isAll = !this.editedItem.MenuExtFoodExternalID ? true : false
      aux.MenuExtFoodExternalID = this.editedItem.MenuExtFoodExternalID ? this.editedItem.MenuExtFoodExternalID.split(',') : null
      this.content = this.editedItem
    },
    prepareMenusName () {
       this.menus = this.menus.map(item => {
        item.Name = utils.getTranslation(item.originalName, this.locale)
        return item
      })
    },
    handleGetMenus () {
      const urlBase = process.env.VUE_APP_API_URL_DTOUCH
      const url = `${window.location.protocol}//${urlBase}/v1/public/workspaces/${this.workspaceID}/menu-ext/`
      fetch(url, { 
        method: 'get', 
        headers: new Headers({
          'Content-Type': 'application/json'
        }), 
      })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        data = data.map(item => {
            return {
              ID: item.ExternalID || item.ID,
              originalName: item.Name,
              Name: utils.getTranslation(item.Name, this.locale)
            }
          })
        let hash = {}
        this.menus = data.filter(o => hash[o.ID] ? false : hash[o.ID] = true)
      })
    },
    handleChange () {
      if (this.isAll) this.content.MenuExtFoodExternalID = null
      this.handleChangeIsModified()
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
  },
}
</script>


<template>
  <v-layout wrap
    :key="workspaceID"
  >
    <v-flex xs4 style="padding: 10px 0 0 10px;">
      <floating-settings
        v-if="config"
        :isFloating="false"
        :editedID="editedID"
        :languages="config.Languages"
      />
    </v-flex>
    <v-flex xs4 style="padding:10px 0 0 0;">
      <content-list-filter
        v-if="workspaceID"
        :locale="locale"
        :workspaceID="workspaceID"
        :onChange="handleChangeContentFilter"
      />
    </v-flex>
    <v-flex xs4>
    </v-flex>
    <v-flex 
      v-bind="{ [`xs${dtouchHideDtouchSettingForm? 12 : 12}`]: true,[`sm${dtouchHideDtouchSettingForm? 12 : 8}`]: true }"
      style="padding: 30px 10px 20px 20px"
    >
      <v-row class="main">
              <!--floating-settings
                v-if="config"
                :editedID="editedID"
                :languages="config.Languages"
              /-->
        <v-col 
          class="simulation-main"
          v-if="!dtouchSimulationHide"
          style="margin-left: 35px;"
        >
          <v-card>
            <v-card-text style="padding: 0px;">
              <full-simulation
                v-if="config && dtouchSimulationShowFullSimulation && !editedID"
                :config="config"
                :refreshSortContent="refreshSortContent"
                :onEditItem="handleEdit"
              />
              <simulation
                v-else-if="config && editedID"
                :id="editedID"
                :parentID="editedParentID"
                :isMenu="isMenuForm"
                :config="config"
                :refreshSortContent="refreshSortContent"
                :onEditItem="handleEdit"
              />
              <v-alert
                v-else
                border="bottom"
                colored-border
                type="warning"
                elevation="2"
              >
                {{$t('content.messages.selectContent', locale)}}
              </v-alert>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="content"
          :style="{ 'margin-left': dtouchSimulationHide ? '35px' : '0' }"
        >
          <draggable-menu-list
            v-if="contentTypes"
            :onEditItem="handleEdit"
            :onCloneItem="handleClone"
            :contentTypes="contentTypes"
            :fullRefresh="fullRefresh"
            :addContent="addContent"
            :moveMenu="moveMenu"
            :refreshContent="refreshContent"
            :removeContent="removeContent"
            :hideByType="hideByType"
            :isEditing="editedID !== null"
            :editedID="editedID"
            :hideVisibility="hideVisibility"
            :cloneMenu="cloneMenu"
            :cloneContent="cloneContent"
            :onSort="handleSortUpdated"
            :contentTypeFilter="contentTypeFilter"
            :onChangeItemsToDelete="handleChangeItemsToDelete"
          />
          <div
            style="margin-top: 20px;"
          >
            <delete-item
              v-if="itemsToDelete && itemsToDelete.length > 0"
              :locale="locale"
              :label="`Eliminar elementos seleccionados [${itemsToDelete.length}]`"
              :onDeleteItem="handleDeleteItemsToDelete"
            />
          </div>
        </v-col>
      </v-row>
    </v-flex>
    <v-flex 
      v-if="!dtouchHideDtouchSettingForm"
      v-bind="{ [`xs${dtouchHideDtouchSettingForm? 0 : 12}`]: true, [`sm${dtouchHideDtouchSettingForm? 0 : 4}`]: true}"
      class="form-content"
    >
      <v-card
        style="min-height:calc(100vh - 104px);"
      >
        <v-card-text>
          <no-edited
            v-if="!editedID"
            :locale="locale"
            :onAddMenu="handleEdit"
          />
          <type-step
            v-else-if="!isMenuForm && editedID === 'new' && !newSelectedContentType"
            :locale="locale"
            :newParentID="editedParentID"
            :onClose="handleCloseForm"
            :onAddSubmenu="handleAddSubmenu"
            :onSelectContentType="handleSelectContentType"
          />
          <form-menu
            v-else-if="isMenuForm"
            :editedID="editedID"
            :newParentID="editedParentID"
            :config="config"
            :onClose="handleCloseForm"
            :isClone="isClone"
            :onAddContent="handleAddContent"
          />
          <form-content
            v-else-if="contentTypes"
            :editedID="editedID"
            :newParentID="editedParentID"
            :newSelectedContentType="newSelectedContentType"
            :config="config"
            :onClose="handleCloseForm"
            :contentTypes="contentTypes"
            :isClone="isClone"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import DeleteItem from '@/components/DeleteItem'
import DraggableMenuList from './listItems/DraggableMenuList'
import ContentListFilter from './listItems/ContentListFilter'
import NoEdited from './NoEdited'
import TypeStep from './TypeStep'
import FormMenu from './FormMenu'
import FormContent from './formContent/Index'
import Simulation from './simulation/Index'
import FullSimulation from './simulation/FullSimulation'
import FloatingSettings from './simulation/floatingSettings/Index'
export default {
  components: {
    DraggableMenuList,
    ContentListFilter,
    NoEdited,
    TypeStep,
    FormMenu,
    FormContent,
    FullSimulation,
    Simulation,
    FloatingSettings,
    DeleteItem,
  },
  data: () => ({
    config: null,
    hideSimulation: false,
    isMenuForm: false,
    editedID: null,
    editedParentID: null, // used to load the menu in the simulation
    contentTypes: null,
    fullRefresh: null,
    addContent: null,
    moveMenu: null,
    refreshSortContent: null, // when sort is update refresh preview
    refreshContent: null,
    removeContent: null,
    hideByType: false,
    hideVisibility: true,
    cloneMenu: false,
    cloneContent: false,
    newSelectedContentType: null, // used to add content
    workspaceID: null,
    isClone: false,
    contentTypeFilter: null,

    itemsToDelete: [], // used to delete multiple menus and components
  }),
  computed: {
    ...mapState('app',['locale', 'dtouchSimulationHide', 'dtouchWorkspace', 'dtouchSimulationShowFullSimulation', 'dtouchHideDtouchSettingForm']),
  },
  watch: {
    dtouchWorkspace (v) {
      this.editedID = null
      this.workspaceID = v
      this.getConfig()
      /*
      this.getContentTypes()
      */
    },
  },
  mounted () {
    if (utils.isMobile()) {
      this.$store.state.app.dtouchSimulationHide = true
      this.$store.state.app.dtouchHideDtouchSettingForm = true
      this.$store.state.app.dtouchSimulationAllowSort = false
    }

    this.$store.state.app.dtouchIsTouchPublic = true // TODO tmp
    this.workspaceID = utils.getWorkspace('dtouch')
    this.getConfig()
    this.getContentTypes()
  },
  methods: {
    getConfig () {
      api.getItem ('dtouch', `v1/internal/workspaces/`,`${this.workspaceID}/deepl-translate/`)
        .then((response) => {
          const { DtouchDeeplTranslateActive, DtouchDeeplTranslateLocale } = response
          this.$store.state.app.dtouchDeeplTranslateActive = DtouchDeeplTranslateActive
          this.$store.state.app.dtouchDeeplTranslateLocale = DtouchDeeplTranslateLocale
        })
      api.getItem ('dtouch', `v1/internal/workspaces/`,`${this.workspaceID}/user-config/`)
        .then((response) => {
          this.hideByType = response.HideByType === 1 ? true : false
          this.cloneMenu = response.CloneMenu === 1
          this.cloneContent = response.CloneContent === 1
        })
      // get color workspaces config for de simulation
      api.getItem ('dtouch', `v1/public/workspaces/`, this.workspaceID)
        .then((response) => {
          if (response.TimeZone) this.$store.state.app.timezone = response.TimeZone
          const aux = JSON.parse(JSON.stringify(response))
          if (aux.Languages) aux.Languages = aux.Languages.split(',')
          else aux.Languages = ["es"]
          this.hideVisibility = aux.AuthType === 'none'
          this.config = aux
        })
    },
    getContentTypes () {
      api.getAllWithoutLimit ('dtouch', 'v1/private/contents/content-types')
        .then((response) => {
          this.contentTypes = response
        })
    },
    handleAddContent (v) {
      if (v.isMenuWithSubmenus) this.handleEdit(true, 'new', v.id)
      else this.handleEdit(false, 'new', v.id)
    },
    handleClone(isMenu, id, parentID) {
      this.isClone = true
      this.isMenuForm = isMenu

      this.editedParentID = parentID // parentID -> used to load the menu in the simulation
      this.editedID = id
    },
    handleEdit (isMenu, id, parentID) {
      this.isClone = false
      this.isMenuForm = isMenu

      this.editedParentID = parentID // parentID -> used to load the menu in the simulation
      this.editedID = id
    },
    handleCloseForm (action, v) {
      if (action ==='addContent')
        this.addContent = {
          item: v,
          lmd: new Date().getTime()
        }
      else if (action === 'updateContent') 
        this.refreshContent = {
            id: v,
            lmd: new Date().getTime()
          }
      else if (action === 'deleteContent')
        this.removeContent = {
            id: v,
            lmd: new Date().getTime()
          }
      else if (action === 'fullRefresh') 
        this.fullRefresh = {
            id: v,
            lmd: new Date().getTime()
          }
      else if (action === 'moveMenu') 
        this.moveMenu = {
            id: v,
            lmd: new Date().getTime()
          }

      this.editedID = null
      this.editedParentID = null

      // reset update automatic content
      this.$store.state.app.dtouchMenuEdit = null
      this.$store.state.app.dtouchContentEdit = null
      this.newSelectedContentType = null // used to add content
    },
    handleSortUpdated (v) {
      // when sort is update refresh preview
      this.refreshSortContent = {
            id: v,
            lmd: new Date().getTime()
          }
      this.$store.state.app.dtouchSortUpdated = new Date().getTime()
    },
    handleChangeContentFilter (v) {
      this.contentTypeFilter = v && v.length ? v : null
    },
    // ------
    handleAddSubmenu () {
      this.isMenuForm = true
    },
    handleSelectContentType (v) {
      this.newSelectedContentType = v // used to add content
      this.getContentTypes()
    },
    handleChangeItemsToDelete (v, active) {
      if (active) {
        this.itemsToDelete.push(v)
      } else {
        this.itemsToDelete = this.itemsToDelete.filter(x => x.id !== v.id)
      }
    },
    handleDeleteItemsToDelete () {
      if (!this.itemsToDelete || this.itemsToDelete.length === 0 || !this.workspaceID) return
      const body = {
        workspaceID: this.workspaceID,
        itemsToDelete: this.itemsToDelete,
      }
      api.put ('dtouch', `v1/private/workspaces/${this.workspaceID}/delete-multi-contents`, body)
        .then((response) => {
          window.location.reload()
        })
    },
    // ------
  },
}
</script>

<style scoped >
.main {
}
.simulation-main {
  padding: 0 10px 0 0;
  max-width: 400px;

  height: calc(100vh - 85px) !important;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none !important;
}
.content {
  width: auto;
  padding: 0px;
  background-color: #FFFFFF80;

  height: calc(100vh - 85px) !important;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none !important;
}
.form-content {
  padding: 20px 20px 20px 10px;

  height: calc(100vh - 85px) !important;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none !important;
}
</style>

